import React from 'react'
import { Link, StaticQuery } from 'gatsby'
import _ from 'lodash'
import Layout from '../../components/layout'
import PageFooter from '../../components/PageFooter'

import mapMarkdownRemarks from '../../components/mapMarkdownRemarks'
import OurTeam from '../../components/OurTeam'
import CenteredParagraph from '../../components/CenteredParagraph'

import withAdminLinks from '../../utils/withAdminLinks';

const OurTeamWithAdminLinks = withAdminLinks(OurTeam);

/**
 * TODO: Include Lab project team members and reduce text bloat on individual profile.
 */
const TeamPage = ({ ourTeam, language }) => {
  return ourTeam ? (
    <Layout className="team-page" language={language}>
      <div className="content" style={{ backgroundColor: 'white', padding: 8 }}>
        <OurTeamWithAdminLinks node={ourTeam} people={ourTeam.entries} />
      </div>
      <PageFooter language={language} />
    </Layout>
  ) : null
}

export const query = graphql`
  query {
    ourTeam: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: "#our-team" } } }
    ) {
      edges {
        node {
          ...AllMarkdownRemarkDataFragment
        }
      }
    }
  }
`

export default ({ data, pageContext }) => {
  const language = _.get(pageContext, 'language')
  const ourTeam = _.first(
    mapMarkdownRemarks(data.ourTeam.edges || [], language)
  )
  return <TeamPage language={language} ourTeam={ourTeam} />
}
