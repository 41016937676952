import React from 'react'
import _ from 'lodash'

import Image from './Image'

const SingleTeamMember = ({ person }) => {
  return (
    <div className="col-md-6 team-member" style={{ paddingBottom: '40px' }}>
      <div className="row">
        <div className="col-md-4">
          {person.images && (
            <Image
              width={120}
              height={120}
              mode="fit"
              style={{ borderRadius: '10px', margin: 'auto' }}
              zzx
              src={person.images[0]}
            />
          )}
          <div className="text-center" style={{ marginTop: 0 }}>
            <h3 style={{ marginBottom: 10, marginTop: 10, fontSize: 20 }}>
              {person.title}
            </h3>
            <p>{person.subtitle}</p>
          </div>
        </div>
        <div className="col-md-7">
          <div
            style={{ paddingRight: 17 }}
            dangerouslySetInnerHTML={{ __html: person.shortExcerpt }}
          />
        </div>
      </div>
    </div>
  )
}

const TeamMembers = ({ title, subtitle, people }) => {
  const memberByTag = _.groupBy(people, item => _.get(item, 'tags[0]'))
  return _.map(memberByTag, (groupMembers, key) => {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-1">
              <div className="section-title text-left">
                <h2 className="title">{key}</h2>
                <p className="sub-title">{subtitle}</p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: 16, marginLeft: 0 }}>
            <div className="col-md-11 col-md-offset-1">
              <div className="row team-members">
                {groupMembers.map((person, index) => (
                  <SingleTeamMember person={person} key={`${index}`} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })
}

export default TeamMembers
